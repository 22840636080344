'use client';
// import { useState, useEffect } from 'react';
import Countdown from 'react-countdown';
import Image from '@/app/components/ui/image';
import AnchorLink from '@/app/components/ui/links/anchor-link';
import placeholder from '@/assets/images/placeholders/product.svg';
import SiteConfig from '@/config/siteconfig';
import Processing from '../ui/loader/processing';

function addMinutes(date, minutes) {
  date.setMinutes(date.getMinutes() + minutes);
  return date;
}
const renderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a complete state
    return <span className="mr-0">{`Expired`}</span>;
  } else {
    // Render a countdown
    return (
      <span className="mr-0">{`Expires in  ${hours}:${minutes}:${seconds}`}</span>
    );
  }
};

export default function CartItem({ item, setExpiredCartKey, expiredCartKey }) {
  const { product, quantity, total, extraData, key: cart_key } = item;

  const cartAddedDateTime = extraData?.filter(
    (x) => x.key == 'card_add_time',
  )[0]?.value;
  const isLotteryInstantWins = extraData?.filter(
    (x) => x.key == 'lottery_instant_wins',
  )[0]?.value;

  return (
    <>
      <div className="items-start w-full gap-1 py-3">
        <div className="flex w-full items-start gap-4 py-3">
          <div className="relative aspect-[5/3.4] w-24 flex-shrink-0 border border-light-300 bg-light-300 dark:border-0 dark:bg-dark-500 xs:w-20">
            <Image
              alt={product?.node?.name}
              fill
              src={product?.node?.image?.sourceUrl ?? placeholder}
              className="object-cover"
            />
          </div>
          <div className="w-[calc(100%-105px)] text-13px font-medium ">
            <h3 className="truncate text-dark dark:text-light">
              <AnchorLink
                href={`/product/${product?.node?.slug}`}
                className="transition-colors hover:text-brand"
              >
                {product?.node?.name}
              </AnchorLink>
            </h3>

            <p className="flex items-center gap-1">
              <span className="rounded-2xl bg-light-300 p-1.5 font-semibold uppercase leading-none text-brand dark:bg-dark-500">
                {total}
              </span>
              <span className="text-light-base dark:text-white rounded-2xl dark:bg-dark-500 p-1.5 text-[11px]">
                {quantity} Ticket (s)
              </span>
            </p>

            <div className="w-full flex justify-center pt-3 text-light-base dark:text-white">
              <div className="text-red-400 text-[14px] font-bold uppercase mr-5">
                <Countdown
                  date={addMinutes(
                    new Date(cartAddedDateTime),
                    SiteConfig?.CART_EXPIRY_MIN || 10,
                  )}
                  renderer={renderer}
                  onComplete={() =>
                    setExpiredCartKey([...expiredCartKey, cart_key])
                  }
                />
              </div>
            </div>
          </div>
        </div>
        {isLotteryInstantWins == 'no' && (
          <>
            <span>Ticket number :</span>
            <div className="mt-2 flex flex-wrap items-center  gap-x-1 text-xs font-normal ">
              {extraData[0].value
                .replace('[', '')
                .replace(']', '')
                .split(',')
                .sort(function (a, b) {
                  return a - b;
                })
                .map((t, i) => (
                  <span
                    key={i}
                    className="bg-brand m-1 flex h-9 w-9 items-center justify-center rounded-full text-black"
                  >
                    {t}
                  </span>
                ))}
            </div>
          </>
        )}
      </div>
      {cart_key && expiredCartKey?.includes(cart_key) ? (
        <Processing />
      ) : (
        <div
          className="h-6 mt-20 hover:text-white relative cursor-pointer px-3 rounded-xl bg-light-300 p-1.5 text-[12px] font-normal  leading-none dark:bg-dark-500"
          onClick={() => setExpiredCartKey([...expiredCartKey, cart_key])}
        >
          Remove
        </div>
      )}
    </>
  );
}
