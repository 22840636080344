// const CMS_URL = 'http://localdev.test';
const CMS_URL = 'https://www.jsvcserve.com'; // Your Wordpress URL-without end slash ,example : 'https://wpbackend.bdsprof.in'
const CMS_CDN_URL = ''; // image CDN link if any, example - 'https://cdn.statically.io/img/wpbackend.bdsprof.in'

// http://localhost:3000/(https://wordpress-1001862-3528855.cloudwaysapps.com/wp-content/uploads/2023/01/LATA-HUMEDOS-PREMIUM-BESTPET-DOG-TROZOS-DE-SALMON-X-400-G.jpg)

const SiteConfig = {
  CMS_URL: CMS_URL,
  CMS_GRAPHQL_URL: CMS_URL + '/graphql',
  CMS_CDN_URL: CMS_CDN_URL,
  SITE_TITLE: 'A4G |  All For Giveaways',
  SITE_DESCRIPTION: 'A4G – All For Giveaways', // Description of your store
  KEY_WORDS: ['Giveaways'],
  LOCALE_NAME: 'en-GB',
  CONTACT_EMAIL_ID: 'andrbappa@gmail.com', // site contact email to send contact email
  SUPPORT_EMAIL_ID: 'info@woocomnext.com', // site support email to send support email
  FRONT_END_SITE_URL: process.env.NEXT_PUBLIC_WEBSITE_URL, // your hosted frontend site URL, Example : 'https://woocomnext.vercel.app'
  PAGE_SIZE: 12, // items per page for pagination
  CART_EXPIRY_MIN: 10,
  GENERATE_ROBOT_TXT: true,
};

module.exports = SiteConfig;
